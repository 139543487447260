//==============================================================================
//==============================================================================
import * as Msdyn365 from '@msdyn365-commerce/core';
import { AttributeValue, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

//==============================================================================
// INTERFACES
//==============================================================================
export interface IGetAttributesForListData {
    [productID: number]: AttributeValue[]
}

type ProductCollection  = Msdyn365.IProductList & {relationshipId: number};

type ProductCollectionConfig = {
    productCollection: ProductCollection
};

//==============================================================================
// FUNCTIONS
//==============================================================================

//==========================================================
//==========================================================
export class GetAttributesForListInput implements Msdyn365.IActionInput {
    public readonly productList: ProductCollection | undefined;

    constructor(productCollection: ProductCollection | undefined) {
        this.productList = productCollection;
    }

    public getCacheKey = () => `${this.productList?.relationshipId}`;
    public getCacheObjectType = () => 'AttributesForList';

    // If the cache key was unique, we could use application caching. However, it would need to include the context as well as the relation type
    public dataCacheType = (): Msdyn365.CacheType => 'request';
}

//==========================================================
//==========================================================
export const createGetAttributesForListInput = (args: Msdyn365.ICreateActionContext<ProductCollectionConfig>): Msdyn365.IActionInput => {
    return new GetAttributesForListInput(args.config?.productCollection);
};

//==========================================================
//==========================================================
export async function getAttributesForListAction(input: GetAttributesForListInput, ctx: Msdyn365.IActionContext): Promise<IGetAttributesForListData> {

    // Ensure we have a productList and product -- this won't be set yet when this is run on the server
    const productIDs = input.productList?.products.map(entry => entry.RecordId);
    if (!productIDs || !productIDs.length) {
        return [];
    }

    // Build a ProductSearchCriteria object
    const criteria: ProductSearchCriteria = {
        Ids: productIDs,
        IncludeProductsFromDescendantCategories: false,
        SkipVariantExpansion: true,
        IncludeAttributes: true,        // The main point of this data action
        Context: {
            ChannelId: ctx.requestContext.channel?.RecordId,
            CatalogId: Msdyn365.getCatalogId(ctx.requestContext)
        },
    };

    // Fetch search results
    const searchResults = await searchByCriteriaAsync({ callerContext: ctx }, criteria);

    // Remap attributes into a friendlier format
    const attributes = searchResults.reduce<IGetAttributesForListData>(
        (output, entry) => {
            output[entry.RecordId] = entry.AttributeValues || [];
            return output;
        },
        {}
    );

    return attributes;
}

//==============================================================================
//==============================================================================
export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetAttributesForListData>>getAttributesForListAction,
    id: 'get-attributes-for-list',                  // This can be anything EXCEPT the data action path ('actions/get-attributes-for-list')
    input: createGetAttributesForListInput
});
