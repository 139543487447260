//==============================================================================
//==============================================================================
import { CacheType, createObservableDataAction, ICommerceApiSettings, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric, getCatalogId } from '@msdyn365-commerce/core';
import { AttributeValue, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync, getAttributeValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getSelectedProductIdFromActionInput, buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';

//==========================================================
//==========================================================
export class GetLocalizedAttributesInput implements IActionInput {

    public productId: number;

    public channelId: number;

    private apiSettings: ICommerceApiSettings;

    private locale: string;

    constructor(productId: number, channelId: number, apiSettings: ICommerceApiSettings, locale: string) {
        this.productId = productId;
        this.channelId = channelId;
        this.apiSettings = apiSettings;
        this.locale = locale;
    }

    public getCacheKey = () => buildCacheKey(`LocalAttributes-${this.productId}`, this.apiSettings, this.locale);

    public getCacheObjectType = () => 'LocalAttributes';

    public dataCacheType = (): CacheType => 'application';

}

//==========================================================
//==========================================================
export const createGetLocalizedAttributesInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {

    const productId = getSelectedProductIdFromActionInput(inputData);
    if (productId) {
        return new GetLocalizedAttributesInput(+productId, +inputData.requestContext.apiSettings.channelId, inputData.requestContext.apiSettings, inputData.requestContext.locale);
    }
    throw new Error('No productId found on query, unable to get product attributes');

};

//==========================================================
//==========================================================
export async function getLocalizedAttributesAction(input: GetLocalizedAttributesInput, ctx: IActionContext): Promise<AttributeValue[]> {

    const catalogId = getCatalogId(ctx.requestContext);

    // Build a ProductSearchCriteria object
    const criteria: ProductSearchCriteria = {
        Ids: [input.productId],
        IncludeProductsFromDescendantCategories: false,
        SkipVariantExpansion: true,
        IncludeAttributes: true,
        Context: {
            ChannelId: input.channelId,
            CatalogId: catalogId
        }
    };

    // Fetch search results containing attribute internal names
    const productSearchResult = await searchByCriteriaAsync({ callerContext: ctx }, criteria);
    const product = productSearchResult[0];

    // Reformat product search attributes for easier lookup
    const internalNameAttributeMap = {};
    product?.AttributeValues?.forEach(attr => {
        if (attr.RecordId) {
            internalNameAttributeMap[attr.RecordId] = attr.Name;
        }
    });

    const attributesWithFriendlyName = await getAttributeValuesAsync({ callerContext: ctx, queryResultSettings: {} }, product.RecordId, input.channelId, catalogId);

    // Combines product search attributes internal name with result
    attributesWithFriendlyName.forEach(attr => {
        if (attr.RecordId) {
            attr.KeyName = internalNameAttributeMap[attr.RecordId];
        }
    });

    return attributesWithFriendlyName;

}

//==============================================================================
//==============================================================================
export default createObservableDataAction({
    action: <IAction<AttributeValue[]>>getLocalizedAttributesAction,
    id: 'get-localized-attributes',
    input: createGetLocalizedAttributesInput
});
